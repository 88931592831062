import classNames from 'classnames';
import { JumpBallStage2Participants } from '../../../types/bwic-process/JumpBallSettings';
import { FormError, RadioButton } from '../../controls';
import { processActions } from '../../../actions';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { useAppSelector } from '../../../effects/useAppSelector';
import { dateTimeUtils } from '../../../utils';
import { constants } from '../../../constants';
import { CutOffReminder } from '../CutOffReminder';
import { SettingsLine } from '../SettingsLine';
import { AutoFeedbackDelay } from '../AutoFeedbackDelay';
import { CutoffReminderSettingsLine } from '../CutoffReminderSettingsLine';
import { MinBidIncrementSettingsLine } from '../MinBidIncrementSettingsLine';
import { useAppDispatch } from '../../../effects/useAppDispatch';

export function JumpBallSection() {
    return (
        <div className="stages">
            <SettingsLine
                title="Autofeedback delay"
                tooltip="The seller can set a delay in sending auto-feedbacks for all bidders. Delay information will not be disclosed to bidders"
            >
                <AutoFeedbackDelay />
            </SettingsLine>
            <MinBidIncrementSettingsLine />
            <h2>Stage 1 — Open bidding with autofeedback</h2>
            <SettingsLine
                title="End Stage 1"
                tooltip={<>The Seller can determine the mode in which the Stage 1 of the BWIC will be completed. Automatically - Stage 1 will be completed automatically according to the time specified in 'Stage 1 duration'.<br />Manually - the Seller manually determines the end of Stage 1 by clicking on the button.</>}
            >
                <Stage1End />
            </SettingsLine>
            <JumpBallCutoffReminder />
            <SettingsLine
                title="# of Stage 2 participants"
                tooltip="Seller defines who is invited to the Stage 2 with Jump Ball. This limitation incentivizes bidders to show their best level earlier in the process."
            >
                <Stage2Participants />
            </SettingsLine>
            <h2>Stage 2 — Jump Ball, a silent stage with one time to improve bid</h2>
            <SettingsLine
                title="Stage 2 duration"
                tooltip={<>If &#34;Manually&#34; - Stage 2 will end according to the end time of BWIC.<br />If &#34;Preset&#34; -  Stage 2 will end according to the set time.</>}
            >
                <Stage2Duration />
            </SettingsLine>
        </div>
    );
}

function JumpBallCutoffReminder() {
    const automaticStage1DeadlineTimeSpan = useAppSelector(s => s.process.templates.current?.jumpBall?.automaticStage1Deadline);

    return (
        <CutoffReminderSettingsLine>
            <CutOffReminder disabled={!automaticStage1DeadlineTimeSpan} />
        </CutoffReminderSettingsLine>
    )
}

function Stage1End() {
    const dispatch = useAppDispatch();

    const automaticStage1DeadlineTimeSpan = useAppSelector(s => s.process.templates.current?.jumpBall?.automaticStage1Deadline);
    const automaticStage1Deadline = automaticStage1DeadlineTimeSpan
        ? dateTimeUtils.parseTimeSpan(automaticStage1DeadlineTimeSpan).totalMinutes
        : undefined;
    const error = useAppSelector(s => s.process.errors.templates.jumpBallStage1AutomaticMinutes)

    return (
        <>
            <div className="radio-group">
                <RadioButton
                    label="Manually"
                    name="stage-1-end"
                    checked={automaticStage1DeadlineTimeSpan == null}
                    onChange={() => {
                        dispatch(processActions.jumpBallStage1EndManual());
                        dispatch(processActions.resetJumpBallStage1AutomaticMinutes());
                    }}
                />
                <RadioButton
                    label="Automatically"
                    name="stage-1-end"
                    checked={automaticStage1DeadlineTimeSpan != null}
                    onChange={() => dispatch(processActions.jumpBallStage1EndAuto())}
                />
            </div>
            <label className="form-label">Stage 1 duration <span className="text-red">*</span></label>
            <div className="form-control-wrapper">
                <MaskedInput
                    value={String(automaticStage1Deadline || "")}
                    disabled={automaticStage1DeadlineTimeSpan == null}
                    placeholder={
                        automaticStage1DeadlineTimeSpan == null
                            ? String(constants.jumpBall.stage1EndAutoDefaultMinutes)
                            : undefined
                    }
                    className={classNames(
                        'form-control range',
                        { 'is-invalid': automaticStage1DeadlineTimeSpan != null && error }
                    )}
                    maxLength={3}
                    mask={createNumberMask({
                        prefix: "",
                        suffix: "",
                        allowDecimal: false,
                        includeThousandsSeparator: false,
                        integerLimit: 3,
                    })}
                    onChange={e => dispatch(processActions.jumpBallStage1EndAutomaticMinutesChange(e.target.value))}
                />
                <span className="info-label">
                    Minutes
                </span >
                <FormError message={automaticStage1DeadlineTimeSpan != null && error} />
            </div>
        </>
    );
}

function Stage2Participants() {
    const dispatch = useAppDispatch();

    const participants = useAppSelector(s => s.process.templates.current?.jumpBall?.improverCount)
        ?? JumpBallStage2Participants.Top3;

    return (
        <div className="radio-group">
            <RadioButton
                label="Top 2"
                name="stage-2-participants"
                checked={participants === JumpBallStage2Participants.Top2}
                onChange={() => dispatch(processActions.jumpBallStage2ParticipantsChange(JumpBallStage2Participants.Top2))}
            />
            <RadioButton
                label="Top 3"
                name="stage-2-participants"
                checked={participants === JumpBallStage2Participants.Top3}
                onChange={() => dispatch(processActions.jumpBallStage2ParticipantsChange(JumpBallStage2Participants.Top3))}
            />
            <RadioButton
                label="Top 4"
                name="stage-2-participants"
                checked={participants === JumpBallStage2Participants.Top4}
                onChange={() => dispatch(processActions.jumpBallStage2ParticipantsChange(JumpBallStage2Participants.Top4))}
            />
            <RadioButton
                label="Top 5"
                name="stage-2-participants"
                checked={participants === JumpBallStage2Participants.Top5}
                onChange={() => dispatch(processActions.jumpBallStage2ParticipantsChange(JumpBallStage2Participants.Top5))}
            />
        </div>
    );
}

function Stage2Duration() {
    const dispatch = useAppDispatch();

    const improvementRoundTimeSpan = useAppSelector(s => s.process.templates.current?.jumpBall?.improvementRound);
    const improvementRound = improvementRoundTimeSpan
        ? dateTimeUtils.parseTimeSpan(improvementRoundTimeSpan).totalMinutes
        : undefined;
    const error = useAppSelector(s => s.process.errors.templates.jumpBallStage2DurationMinutes)

    return (
        <>
            <div className="radio-group stage-2-duration">
                <RadioButton
                    label="Manually"
                    name="stage-2-duration"
                    checked={improvementRoundTimeSpan == null}
                    onChange={() => {
                        dispatch(processActions.jumpBallStage2DurationUnspecified());
                        dispatch(processActions.resetJumpBallStage2DurationMinutes());
                    }
                    }
                />
                <RadioButton
                    label="Preset"
                    name="stage-2-duration"
                    checked={improvementRoundTimeSpan != null}
                    onChange={() => dispatch(processActions.jumpBallStage2DurationPreset())}
                />
                <label className="form-label"><span className="text-red">*</span></label>
            </div>
            <div className="form-control-wrapper">
                <MaskedInput
                    value={String(improvementRound || "")}
                    placeholder={
                        improvementRoundTimeSpan == null
                            ? String(constants.jumpBall.stage2DurationDefaultMinutes)
                            : undefined
                    }
                    disabled={improvementRoundTimeSpan == null}
                    className={classNames(
                        'form-control range',
                        { 'is-invalid': improvementRoundTimeSpan != null && error }
                    )}
                    maxLength={3}
                    mask={createNumberMask({
                        prefix: "",
                        suffix: "",
                        allowDecimal: false,
                        includeThousandsSeparator: false,
                        integerLimit: 3,
                    })}
                    onChange={e => dispatch(processActions.jumpBallStage2DurationMinutesChange(e.target.value))}
                />
                <span className="info-label">
                    Minutes
                </span >
                <FormError message={improvementRoundTimeSpan != null && error} />
            </div>
        </>
    );
}
